import { Fragment, ReactNode, useState, useEffect } from 'react'
import { Box, Flex, Stack, SystemProps, Icon } from '@storyofams/react-ui'
import { useRouter } from 'next/router'
import { defineMessages, useIntl } from 'react-intl'
import { getLinkProps } from '~lib'
import { Divider, Text, Heading, Button } from '~/components'
import { Logo } from '~/components/common/Icons'
import {
  NavigationItem,
  FooterItem,
  ActiontickerItem,
} from '~lib/storyblok/sdk'

import { LanguageSwitch } from '../LanguageSwitch'
import { Container } from '../Layout/Container'
import { Navigation } from '../Navigation'
import {
  ActionTicker,
  NavigationLink,
  NavigationPrimary,
} from '../Navigation/components'
import { NewsletterForm } from '../NewsletterForm'
import { Footer } from '../NewFooter'

interface LayoutProps extends SystemProps {
  actionTicker: ActiontickerItem
  children: ReactNode
  footer: FooterItem
  navigation: NavigationItem
}

const messages = defineMessages({
  signUp: 'Sign-up to stay updated!',
  copyright: '© 2021 Vood',
  mySubscription: 'My subscription',
})

export const Layout = ({
  children,
  actionTicker,
  footer,
  navigation,
  ...props
}: LayoutProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { pathname, locale: routerLocale } = useRouter()
  const intl = useIntl()
  const locale = (routerLocale || process.env.DEFAULT_LOCALE)?.toUpperCase()
  const [accountLink, setAccountLink] = useState('')

  useEffect(() => {
    switch (locale) {
      case 'NL':
        setAccountLink(process.env.NEXT_PUBLIC_SELF_SERVICE_NL)
        break
      case 'EN':
        setAccountLink(process.env.NEXT_PUBLIC_SELF_SERVICE_EN)
        break
    }
  }, [routerLocale])

  return (
    <Flex flexDirection="column" width="100%">
      {!!actionTicker?.published_at && actionTicker.content?.items?.length && (
        <ActionTicker actionTicker={actionTicker} />
      )}

      <Navigation>
        <NavigationPrimary
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        >
          <Flex
            flex={1}
            justifyContent="space-between"
            alignItems={['flex-start', 'flex-start', 'center']}
            height={{ md: '100%' }}
          >
            <Stack
              space={[5]}
              flexDirection={['column', 'column', 'row']}
              alignItems={['flex-start', 'flex-start', 'center']}
              justifyContent={['flex-start', 'flex-start', 'flex-end']}
              height={['auto', 'auto', '100%']}
              width={['100%', '100%', 'auto']}
              fontSize={2}
              flex="1"
            >
              {navigation?.content?.link_list?.map(({ label, url, _uid }) => (
                <Fragment key={_uid}>
                  <NavigationLink
                    link={getLinkProps(url)}
                    alignItems={['initial', 'initial', 'center']}
                    justifyContent={['initial', 'initial', 'center']}
                    color={'#FFFFFF'}
                    style={{ textTransform: 'uppercase' }}
                    letterSpacing={'2px'}
                  >
                    {label}
                  </NavigationLink>
                </Fragment>
              ))}
              <LanguageSwitch storySlugs={navigation} />
              {
                <Button
                  href={`${accountLink}`}
                  variant="button_type_2"
                  zIndex="skipLink"
                  whiteSpace="nowrap"
                  display={['none', 'none', 'flex']}
                >
                  {intl.formatMessage(messages.mySubscription)}
                </Button>
              }
            </Stack>
          </Flex>
        </NavigationPrimary>
      </Navigation>

      <Box
        backgroundColor={pathname == '/meals' ? '#FAF7F5' : 'inherit'}
        as="main"
        flexDirection="column"
        flex={1}
        width="100%"
        {...props}
      >
        {children}
      </Box>

      <Footer data={footer} />
      {/* <Container
        bg="grey200"
        as="footer"
        pt={[4, 10, 15]}
        pb={3}
        space={[4, 8]}
      >
        {pathname != '/order/overview'
          ? (
            <>
              <Flex flexDirection="column" alignItems="center">
                <Box py={3} px={2.5} bg="white" mb={[3, 5]}>
                  <Icon icon={Logo} fontSize={55} />
                </Box>

                <Heading variant="h4" as="h4" textAlign="center" mb={[7, 5]}>
                  {intl.formatMessage(messages.signUp)}
                </Heading>

                <NewsletterForm formType="footer" />
              </Flex>

              <Divider color="grey500" />
            </>
          ) : ''
        }
        <Stack space={[5, 10, 200]} flexDirection={['column', 'column', 'row']}>
          {footer?.content?.link_list?.map(({ list }, index) => (
            <Stack
              space={[3, 3, 4]}
              flexDirection={['column']}
              height={['auto', 'auto', '100%']}
              width={['100%', '100%', 'auto']}
              fontSize={2}
              lineHeight="high"
              key={`${list?._uid ?? index}-stack`}
            >
              {list?.map(({ label, url, _uid }) => (
                <Fragment key={_uid}>
                  <NavigationLink link={getLinkProps(url)}>
                    {label}
                  </NavigationLink>
                </Fragment>
              ))}
            </Stack>
          ))}
        </Stack>

        <Divider color="grey500" />
        <LanguageSwitch
          storySlugs={footer}
          alignSelf={['flex-start', 'flex-end']}
          mb={2}
        />
        <Text
          variant="s"
          mt="24px !important"
          alignSelf={['center', 'flex-end']}
        >
          {intl.formatMessage(messages.copyright)}
        </Text>
      </Container> */}
    </Flex>
  )
}
