import { Box, SystemProps } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { HeightProps, WidthProps } from 'styled-system';

export const getHighestValue = (value: any): number | string => {
  switch (typeof value) {
    case 'number':
      return value;
    case 'object':
      if (Array.isArray(value)) {
        return value
          .map(getHighestValue)
          .sort((a, b) => Number(b) - Number(a))[0];
      }
      return Object.values(value)
        .map(getHighestValue)
        .sort((a, b) => Number(b) - Number(a))[0];
    case 'string':
      if (value.includes('%')) {
        return value;
      }
      return parseInt(value);
  }
};

export type ImageProps = Omit<
  NextImageProps,
  'layout' | 'width' | 'height' | 'placeholder'
> &
  Omit<SystemProps, 'width' | 'height'> &
  (
    | {
        layout: 'fill';
        width?: WidthProps['width'];
        height?: HeightProps['height'];
        placeholder?: string;
      }
    | {
        layout?: 'fixed' | 'intrinsic' | 'responsive';
        width: WidthProps['width'];
        height: HeightProps['height'];
        placeholder?: string;
      }
  );

/**
 * @description Image component which uses Rebass as Next's Image component. When you use this component and you're not certain of the source
 * domain of the image (i.e. user input) use, make sure to use the `unoptimized` prop. Otherwise declare the domain of the image in the `next.config.js`
 */

export const Image = ({ placeholder, ...props }: ImageProps) => {
  const nextImageProps = omit(props) as Omit<
    NextImageProps,
    'width' | 'height' | 'placeholder'
  >;

  if (props.layout === 'fill') {
    return (
      <Box {...pick(props)} position="relative" data-testid="image-fill">
        <NextImage
          layout="fill"
          {...nextImageProps}
          placeholder={placeholder ? "blur" : "empty"}
          blurDataURL={placeholder}
        />
      </Box>
    );
  }

  return (
    <Box {...pick(props)} data-testid="image">
      <NextImage
        {...nextImageProps}
        placeholder={placeholder ? "blur" : "empty"}
        blurDataURL={placeholder}
        width={getHighestValue(props.width)}
        height={getHighestValue(props.height)}
      />
    </Box>
  );
};
