import { FC } from 'react';
import { Flex, css, SystemProps } from '@storyofams/react-ui';
import Link from 'next/link';

interface NavigationLinkProps extends SystemProps {
  link: string;
  active?: boolean;
  style?: object;
}

export const NavigationLink: FC<NavigationLinkProps> = ({
  link,
  active,
  children,
  ...props
}) => {
  const socialUrls = [
    'www.linkedin.com',
    'www.facebook.com',
    'www.instagram.com'
  ]
  
  const isSocialUrl = (url: string) => {
    for(let i=0; i<socialUrls.length; i++)
      if(url.includes(socialUrls[i]))
        return true

    return false
  }

  const linkContent = (tagType: any = "a") => {
    return (
      <Flex
        as={tagType}
        position="relative"
        height={['auto', 'auto', '100%']}
        width={['100%', '100%', 'auto']}
        fontSize={[2]}
        color="black"
        css={css({
          '&:hover': {
            color: 'grey700',
          },
          '&:after': {
            content: "''",
            position: 'absolute',
            left: active ? 0 : '50%',
            right: active ? 0 : '50%',
            bottom: 0,
            height: '4px',
            backgroundColor: 'secondary',
            transition: 'left 0.18s ease-in-out, right 0.18s ease-in-out',
            opacity: [0, 0, active ? 1 : 0],
          },
        })}
        {...props}
      >
        {children}
      </Flex>
    )
  }

  return (
    <Link href={link} passHref>
      {isSocialUrl(link) ? <a target='_blank' href={link} rel='noopener noreferrer' >{linkContent('span')}</a> : linkContent('a')}
    </Link>
  );
};
