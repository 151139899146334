import { ReactNode } from 'react';
import { SystemProps } from '@storyofams/react-ui';

import { Text } from '~components/common/Text';

interface BadgeProps extends SystemProps {
  children: ReactNode;
}

export const Badge = ({ children, ...props }: BadgeProps) => (
  <Text
    variant="s"
    display="flex"
    alignItems="center"
    justifyContent="center"
    fontWeight="bold"
    width="16px"
    height="16px"
    borderRadius="full"
    bg="error600"
    color="white"
    {...props}
  >
    {children}
  </Text>
);
