import { Box, system, SystemProps } from '@storyofams/react-ui'
import styled from 'styled-components'
import { variant, ResponsiveValue } from 'styled-system'

export const headingVariants = {
  h1: {
    fontSize: ['heading'],
  },
  h2: {
    fontSize: ['28px', 8],
  },
  h3: {
    fontSize: ['28px', 6],
  },
  h4: {
    fontSize: [3, '36px'],
  },
  h5: {
    fontSize: [2, 3],
  },
  h6: {
    fontSize: [2],
  },
  sh1: {
    fontSize: ['25px', '35px'],
    fontWeight: 'bold',
  },
  sh2: {
    fontSize: ['32px', '35px'],
    textTransform: 'uppercase',
    lineHeight: '130%',
    letterSpacing: '0.006em',
  },
  sh3: {
    fontSize: [3],
    fontWeight: 'bold',
  },
  sh4: {
    fontSize: '20px',
    lineHeight: '130%',
    fontWeight: 'bold',
  },
  sh5: {
    fontSize: '30px',
    lineHeight: '130%',
    fontWeight: 'bold',
  },
  brandL: {
    fontFamily: 'Plant B',
    fontSize: [4, 'heading'],
  },
  brandM: {
    fontFamily: 'Plant B',
    fontSize: [4, 8],
  },
  brandS: {
    fontFamily: 'Plant B',
    fontSize: [6],
  },
}

export type CustomProps = {
  variant:
    | ResponsiveValue<keyof typeof headingVariants>
    | keyof typeof headingVariants
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
} & SystemProps

export const Heading = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? 'h1',
}))<CustomProps>`
  font-family: ${p => p.theme.fonts.heading};
  font-weight: ${p => p.theme.fontWeights.extraBold};
  color: ${p => p.theme.colors.grey900};

  ${variant({ variants: headingVariants })}
  ${system};
`
