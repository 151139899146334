import { Grid, SystemProps, Box, Flex } from '@storyofams/react-ui';
import { Text, Button } from '~components';
import dynamic from 'next/dynamic';
import SbEditable from 'storyblok-react';

import type {
  LinkListBlock as LinkListBlockType,
  SubscribeBlock as SubscribeBlockType,
  FollowUsBlock as FollowUsBlockType,
} from './sections';

const LinkListBlock = dynamic(() =>
  import('./sections/LinkListBlock').then((mod) => mod.LinkListBlock),
) as typeof LinkListBlockType;
const SubscribeBlock = dynamic(() =>
  import('./sections/SubscribeBlock').then((mod) => mod.SubscribeBlock),
) as typeof SubscribeBlockType;
const FollowUsBlock = dynamic(() =>
  import('./sections/FollowUsBlock').then((mod) => mod.FollowUsBlock),
) as typeof FollowUsBlockType;


const Components = {
  link_list_v2: LinkListBlock,
  subscribe_footer_v2: SubscribeBlock,
  follow_us_footer_v2: FollowUsBlock,
};

export interface FooterProps extends SystemProps {
  data?: any
}

export const Footer = ({ data, ...props }: FooterProps) => {
  const { content } = data;
  let customProps = {} as any

  if (!!content?.background_color) {
    customProps.backgroundColor = content.background_color.color
  }

  if (!!content?.font_color) {
    customProps.color = content.font_color.color
  }

  return (
    <>
      <SbEditable content={content}>
        <Flex
          width="100%"
          px={[4, 6, 10]}
          py={[4, 4, 8]}
          alignItems={'center'}
          flexDirection={'column'}
          {...customProps}
        > 
          <Flex
            maxWidth={'1440px'}
            width={'100%'}
            justifyContent={'space-between'}
            style={{ rowGap: '56px', columnGap: '20px' }}
            flexWrap={'wrap'}
          >
            {!!content?.blocks
              ? content.blocks.map(block => {
                if (
                  !!block &&
                  typeof Components[block?.component] !== 'undefined'
                ) {
                  const Component = Components[block?.component];
                  return (
                    <Component
                      key={block?._uid}
                      content={block}
                      customProps={customProps}
                      {...props}
                    />
                  )}
                })
              : null
            }
          </Flex>
          {(
            (!!content?.footer_bottom_links && content?.footer_bottom_links.length) ||
            (!!content?.footer_bottom_text_blocks && content?.footer_bottom_text_blocks.length)
              ? (
                <Flex
                  maxWidth={'1440px'}
                  width={'100%'}
                  gap={[3, 0]}
                  justifyContent={['space-between']}
                  mt={['50px', '48px']}
                  mb={['0', '0','-40px']}
                  flexDirection={['column', 'row']}
                >
                  <Flex gap={[1, 5]} flexDirection={['column', 'row']}>
                    {!!content?.footer_bottom_text_blocks && content?.footer_bottom_text_blocks.length
                      ? content?.footer_bottom_text_blocks.map(el => <Text key={el._uid} fontWeight={'600'} {...customProps}>{el.text}</Text>)
                      : ''
                    }
                  </Flex>
                  <Flex
                    alignItems={['flex-start', 'flex-end']}
                    gap={[1, 5]} flexDirection={['column', 'row']}
                  >
                    {!!content?.footer_bottom_links && content?.footer_bottom_links.length
                      ? content?.footer_bottom_links.map(el => (
                          <Button
                            key={el?._uid}
                            variant="link"
                            to={el.url.cached_url}
                            fontWeight={'600'}
                            textDecoration={'none'}
                            {...customProps}
                          >
                            {el?.label}
                          </Button>
                        ))
                      : ''
                    }
                  </Flex>
                </Flex>
              )
              : ''
          )}
        </Flex>
      </SbEditable>
    </>
  );
};
