import { Icon, SystemProps } from '@storyofams/react-ui';

import { Button } from '~components';
import {
  NewCart as CartIcon,
  Cart as DarkCartIcon
} from '~components/common/Icons';
import { useRouter } from 'next/router';
import { Badge } from './Badge';

interface Props extends SystemProps {
  quantity?: number;
  cartOpen?: boolean;
  setCartOpen?: Function;
  toggleCartOverlay?: Function;
  darkIcon?: boolean;
  customIconWidth?: string;
}

export const CartButton = ({
  quantity,
  cartOpen,
  toggleCartOverlay,
  backgroundColor,
  darkIcon,
  customIconWidth,
}: Props) => {
  const { push: routerPush } = useRouter()
  const buttonContent = (
    <>
      <Icon icon={darkIcon ? DarkCartIcon : CartIcon} color="black" fontSize={customIconWidth ? customIconWidth : 4} />

      {!!quantity && (
        <Badge
          position="absolute"
          top="-2px"
          right={['-12px', '-12px', '-10px']}
          bg={backgroundColor ? backgroundColor : 'error600'}
        >
          {quantity}
        </Badge>
      )}
    </>
  );

  return (
    <Button
      variant="unstyled"
      onClick={!!toggleCartOverlay ? () => toggleCartOverlay(!cartOpen) : () => routerPush('/order/overview')}
      // onFocus={() => cartOpen}
      // onClick={() => {
      //   routerPush('/order/overview')
      // }}
      display="inline-flex"
      minWidth={['32px', '32px', '40px']}
      height="40px"
      alignItems="center"
      justifyContent="center"
    >
      {buttonContent}
    </Button>
  );
};
