import React, { FC } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';

export const Divider: FC<SystemProps> = ({
  children,
  color = 'black',
  ...props
}) => {
  return (
    <Box
      width="100%"
      minHeight="1px"
      maxHeight="1px"
      backgroundColor={color}
      {...props}
    />
  );
};
