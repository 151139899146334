import { useState, useEffect } from 'react';
import { Text, SystemProps, Flex, Stack } from '@storyofams/react-ui';
import { isAfter } from 'date-fns';

import { Button } from '~components';

interface ActionTickerProps extends SystemProps {
  actionTicker?: any;
  backgound_color?: string;
  text_color?: string;
}

export const ActionTicker = ({
  actionTicker: actionTickerData,
}: ActionTickerProps) => {
  const [actionTicker, setActionTicker] = useState(false);

  useEffect(() => {
    const actionTickerDate = localStorage.getItem('actionTickerDate');

    if (actionTickerData) {
      setActionTicker(
        !actionTickerDate ||
          isAfter(actionTickerData?.published_at, new Date(actionTickerDate)),
      );
    }
  }, []);

  if (!!!actionTicker || !!!actionTickerData) {
    return null;
  }

  const mobileItemIndex = actionTickerData?.content?.items?.findIndex(
    (item) => item.showOnMobile,
  );
  const idx = mobileItemIndex >= 0 ? mobileItemIndex : 0;

  return (
    <Stack
      space={2}
      alignItems="center"
      justifyContent="center"
      position="relative"
      width="100%"
      px={[2]}
      py={[0.5, 0.5, 0]}
      backgroundColor={!!actionTickerData?.content?.background_color?.color ?
        actionTickerData?.content?.background_color?.color : "lightgreen"}
      textAlign="center"
      overflow="hidden"
    > 
      {actionTickerData?.content?.items?.map(({ link, text, _uid }, i) => (
        <Flex
          display={[
            i === idx ? 'flex !important' : 'none !important',
            i === idx ? 'flex !important' : 'none !important',
            'flex !important',
          ]}
          alignItems="center"
          key={_uid}
        >
          {link?.url ? (
            <Button as="a" variant="link" to={actionTickerData?.link?.url}>
              <Text lineHeight="medium" color="black" margin={0}>
                {text}
              </Text>
            </Button>
          ) : (
            <Text color={!!actionTickerData?.content?.text_color?.color ?
              actionTickerData?.content?.text_color?.color : "black"}
              margin={0}
              lineHeight="medium">
              {text}
            </Text>
          )}

          {i < actionTickerData?.content?.items?.length - 1 && (
            <Text
              as="span"
              ml={2}
              color="black"
              display={['none', 'none', 'flex']}
            >
              •
            </Text>
          )}
        </Flex>
      ))}
    </Stack>
  );
};
