import React, { ReactNode } from 'react';
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
  DialogOverlayProps,
} from '@reach/dialog';
import { Icon, Close, Flex, SystemProps, Box } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Heading, Divider } from '~components';

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDrawer = motion(ReachDialogContent);

const Overlay = styled(MotionOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: ${(p) => p.theme.zIndices.modal};

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(p: any) => !!p.customBackgroundOverlay ? p.customBackgroundOverlay : 'rgba(0, 0, 0, 0.4)'};

  & > div > div {
    border-top-right-radius: 16px;
  }
`;

const Content = styled(MotionDrawer)<
  SystemProps & { children: ReactNode; from: 'right' | 'left' }
>`
  position: fixed;
  ${(p) => (p.from === 'left' ? 'left: 0' : 'right: 0')};
  top: 0;
  bottom: 0;

  flex: 1;

  width: 90%;
  min-width: 320px;
  max-width: 388px;
  height: 100vh;

  padding: 0;
  margin: 0;

  background: transparent;
`;

const ContentWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${(p) => p.theme.colors['white']};
  z-index: ${(p) => p.theme.zIndices.modal + 1};

  &:after {
    content: '';
    position: fixed;
    top: 0;
    width: 360px;
    height: ${(p) => p.theme.space[4]}px;
    background-color: ${(p) => p.theme.colors['white']};
  }
`;

const CloseButton = styled(Box).attrs(() => ({ as: 'button' }))`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 40px;
  height: 40px;

  transition: color 0.2s;

  &:hover {
    svg {
      color: ${(p) => p.theme.colors.grey400};
    }
  }
`;

interface Props extends DialogOverlayProps, SystemProps {
  isOpen: boolean;
  close(any): void;
  title?: string | ReactNode;
  ariaLabel?: string;
  children?: ReactNode;
  header?: ReactNode;
  from?: 'right' | 'left';
  withOverflowHidden?: boolean;
  customBackgroundOverlay?: string;
  CustomBottomElement?: any;
}

export const Drawer = ({
  children,
  ariaLabel,
  title,
  isOpen,
  close,
  from = 'right',
  bg,
  header,
  withOverflowHidden,
  customBackgroundOverlay,
  CustomBottomElement,
  ...props
}: Props) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          onDismiss={close}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: 0.24 }}
          customBackgroundOverlay={customBackgroundOverlay}
          {...omit(props)}
        >
          <Content
            aria-label={ariaLabel || 'modal'}
            initial={{ x: from === 'left' ? '-100%' : '100%' }}
            exit={{ x: from === 'left' ? '-100%' : '100%' }}
            animate={{ x: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.24 }}
            from={from}
          >
            <ContentWrapper
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.32 }}
            >
              <Flex
                flexDirection="column"
                height="100%"
                width="100%"
                {...pick(props)}
              >
                <Flex
                  position="sticky"
                  top={0}
                  flexShrink={0}
                  flexDirection="column"
                  pl={[10]}
                  pr={[3]}
                  pt={[7]}
                  zIndex="modal"
                  bg="white"
                >
                  <Flex
                    flex={1}
                    alignItems="center"
                    justifyContent={title ? 'space-between' : 'flex-end'}
                  >
                    {title && (
                      <>
                        {typeof title === 'string' ? (
                          <Heading as="h2" variant="sh2" flex={1}>
                            {title}
                          </Heading>
                        ) : (
                          title
                        )}
                      </>
                    )}
                    <CloseButton
                      onClick={close}
                      aria-label="Close modal"
                    >
                      <Icon icon={<Close />} fontSize={2} color="grey900" />
                    </CloseButton>
                  </Flex>

                  {header}
                  {/* <Divider bg="grey400" mt={[3, 4]} /> */}
                </Flex>

                <Flex
                  flex={1}
                  flexDirection="column"
                  pl={[10]}
                  pr={[3]}
                  py={[3]}
                  overflowY={withOverflowHidden ? 'hidden' : 'auto'}
                >
                  {children}
                </Flex>
                {!!CustomBottomElement && <CustomBottomElement />}
              </Flex>
            </ContentWrapper>
          </Content>
        </Overlay>
      )}
    </AnimatePresence>
  );
};
