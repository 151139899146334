import React, { forwardRef, ComponentProps, ReactNode, Ref } from 'react';
import { Icon, SystemProps, Box, Spinner, system } from '@storyofams/react-ui';
import Link from 'next/link';
import type { PolymorphicForwardRefExoticComponent } from 'react-polymorphic-types';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

const _defaultElement = 'button';

const baseStyles = {
  fontSize: 2,
  px: 4,
  py: 2,
  height: '48px',
};

const variants = {
  primary: {
    ...baseStyles,
    bg: 'black',
    color: 'white',
    border: '1px solid',
    borderColor: 'black',

    '&:not(:disabled)': {
      '&:hover, &:active': {
        bg: 'grey800',
        borderColor: 'grey800',
      },
    },
  },
  secondary: {
    ...baseStyles,
    bg: 'white',
    color: 'black',
    border: '2px solid',
    borderColor: 'black',

    '&:not(:disabled)': {
      '&:hover, &:active': {
        backgroundColor: 'grey300',
      },
    },
  },
  outline: {
    ...baseStyles,
    color: 'black',
    border: '1px solid',
    borderColor: 'black',

    '&:not(:disabled)': {
      '&:hover, &:active': {
        color: 'grey700',
        borderColor: 'grey700',
      },
    },
  },
  link: {
    color: 'black',
    display: 'inline-flex',
    textDecoration: 'underline',

    '&:hover, &:active, &:focus': {
      color: 'grey500',
    },
  },
  link_nav: {
    color: 'black',
    display: 'inline-flex',
    fontSize: '20px',
    lineHeight: '25px',
    textTransform: 'capitalize',
    borderRadius: '0',
    padding: '8px 12px',
    '&:hover': {
      color: '#21857F',
    },
  },
  link_nav_selected: {
    
    color: '#21857F',
    display: 'inline-flex',
    fontSize: '20px',
    lineHeight: '25px',
    fontWeight: '700',
    textTransform: 'capitalize',
    borderRadius: '0',
    padding: '8px 12px',
    background: 'rgba(150, 192, 187, 0.1)',
    '&:hover': {
      color: '#21857F',
    },
  },
  unstyled: {
    '&:disabled': { cursor: 'not-allowed', color: 'grey500' },
  },
  blue_primary: {
    ...baseStyles,
    bg: '#bfe2d2ff',
    color: 'black',
    border: '1px solid',
    borderColor: '#bfe2d2ff',

    '&:not(:disabled)': {
      '&:hover, &:active': {
        color: 'grey700',
        bg: '#bfe2d2ff',
        borderColor: '#bfe2d2ff',
      },
    },
  },
  lightgreen: {
    ...baseStyles,
    bg: 'rgb(27, 24, 25)',
    color: '#fff',
    border: '1px solid',
    borderColor: 'rgb(27, 24, 25)',
    opacity: '.25',
    fontWeight: '600',
    textTransform: 'uppercase',

    '@media (min-width: 1024px)': {
      paddingLeft: '97px', 
      paddingRight: '97px',
    },

    '&:not(:disabled)': {
      opacity: '1',
      bg: '#22d160',
      color: '#fff',
      borderColor: '#22d160',
      '&:hover, &:active': {
        bg: '#f58523',
        borderColor: '#f58523',
      },
    },
  },
  lightgreen_2: {
    ...baseStyles,
    bg: 'rgb(27, 24, 25)',
    border: '1px solid',
    borderColor: 'rgb(27, 24, 25)',
    opacity: '.25',
    fontWeight: '400',

    '&:not(:disabled)': {
      opacity: '1',
      bg: '#22d160',
      borderColor: '#22d160',
      '&:hover, &:active': {
        bg: '#f58523',
        borderColor: '#f58523',
      },
    },
  },
  green: {
    ...baseStyles,
    bg: 'rgb(27, 24, 25)',
    color: '#fff',
    border: '1px solid',
    borderColor: 'rgb(27, 24, 25)',
    opacity: '.25',
    fontWeight: '600',
    textTransform: 'uppercase',

    '@media (min-width: 1024px)': {
      paddingLeft: '97px', 
      paddingRight: '97px',
    },

    '&:not(:disabled)': {
      opacity: '1',
      bg: '#269B1C',
      color: '#fff',
      borderColor: '#269B1C',
      '&:hover, &:active': {
        bg: '#f58523',
        borderColor: '#f58523',
      },
    },
  },
  overview_button: {
    ...baseStyles,
    fontSize: '20px',
    color: '#161615',
    border: '1px solid',
    borderColor: '#D8D8D9',
    fontWeight: '400',
    bg: '#FFF',
    '&:hover, &:active': {
      borderColor: '#69A8A2',
    },
  },
  overview_button_selecter: {
    ...baseStyles,
    fontSize: '20px',
    color: '#21857F',
    border: '1px solid',
    borderColor: '#69A8A2',
    fontWeight: '700',
    bg: 'rgba(150, 192, 187, 0.1)',
  },
  pink_big: {
    ...baseStyles,
    bg: '#F090B9',
    boxShadow: '0px 4px 0px #F0609E',
    color: '#fff',
    border: '1px solid',
    borderRadius: '40px',
    borderColor: 'rgb(27, 24, 25)',
    opacity: '.25',
    fontWeight: '700',
    padding: '12px 32px',
    fontSize: 3,
    lineHeight: '150%',
    height: ['51px', '48px'],
    '&:not(:disabled)': {
      opacity: '1',
      bg: '#F090B9',
      color: '#fff',
      borderColor: '#F090B9',
      '&:hover, &:active': {
        boxShadow: '0px 0px 0px #F0609E',
      },
    },
  },
  pink_small: {
    ...baseStyles,
    bg: '#F090B9',
    boxShadow: '0px 4px 0px #F0609E',
    color: '#fff',
    border: '1px solid',
    borderRadius: '40px',
    borderColor: 'rgb(27, 24, 25)',
    opacity: '.25',
    fontWeight: '700',
    padding: '12px 32px',
    fontSize: 2,
    lineHeight: '150%',
    height: ['51px', '48px'],
    '&:not(:disabled)': {
      opacity: '1',
      bg: '#F090B9',
      color: '#fff',
      borderColor: '#F090B9',
      '&:hover, &:active': {
        boxShadow: '0px 0px 0px #F0609E',
      },
    },
  },
  button_type_1: {
    ...baseStyles,
    bg: '#D8D8D9',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '40px',
    opacity: '1',
    fontWeight: '700',
    padding: '0 15px',
    fontSize: 2,
    lineHeight: '100%',
    letterSpacing: '1.6px',
    textTransform: 'uppercase',
    height: ['45px'],
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    '&:not(:disabled)': {
      bg: '#DB7A51',
      color: '#FFFFFF',
      '&:hover': {
        bg: '#69A8A2',
        color: '#FFFFFF',
      },
      '&:active': {
        bg: '#21857F',
        color: '#FFFFFF',
      }
    },
  },
  button_type_2: {
    ...baseStyles,
    bg: '#F7F3EF',
    color: '#69A8A2',
    border: 'none',
    borderRadius: '40px',
    opacity: '1',
    fontWeight: '700',
    padding: '0 15px',
    fontSize: 2,
    lineHeight: '100%',
    letterSpacing: '1.6px',
    textTransform: 'uppercase',
    height: ['45px'],
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    '&:not(:disabled)': {
      bg: '#FAF7F5',
      color: '#21857F',
      '&:hover, &:active': {
        bg: '#DB7A51',
        color: '#FFFFFF',
      },
    },
  },
  button_type_3: {
    ...baseStyles,
    bg: 'transparent',
    color: '#D8D8D9',
    borderRadius: '40px',
    opacity: '1',
    fontWeight: '700',
    padding: '0 15px',
    fontSize: 2,
    lineHeight: '100%',
    letterSpacing: '1.6px',
    textTransform: 'uppercase',
    height: ['45px'],
    minWidth: 'fit-content',
    border: '2px solid #D8D8D9',
    whiteSpace: 'nowrap',
    '&:not(:disabled)': {
      bg: 'transparent',
      color: '#69A8A2',
      border: '2px solid #69A8A2',
      '&:hover': {
        bg: '#69A8A2',
        color: '#FFFFFF',
      },
      '&:active': {
        bg: '#21857F',
        color: '#FFFFFF',
      }
    },
  },
  button_type_4: {
    ...baseStyles,
    bg: '#F7F3EF',
    color: '#F7F3EF',
    border: '2px solid #F7F3EF',
    borderRadius: '40px',
    opacity: '1',
    fontWeight: '700',
    padding: '0 15px',
    fontSize: 2,
    lineHeight: '100%',
    letterSpacing: '1.6px',
    textTransform: 'uppercase',
    height: ['45px'],
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    '&:not(:disabled)': {
      bg: '#F7F3EF',
      color: '#B55B99',
      '&:hover, &:active': {
        bg: '#F1EBE5',
        color: '#B55B99',
      },
    },
  },
  link_filter: {
    ...baseStyles,
    bg: '#F7F3EF',
    color: '#69A8A2',
    border: 'none',
    borderRadius: '40px',
    opacity: '1',
    fontWeight: '700',
    padding: '0 15px',
    fontSize: 2,
    lineHeight: '100%',
    letterSpacing: '1.6px',
    textTransform: 'uppercase',
    height: ['45px'],
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    '&:not(:disabled)': {
      bg: 'transparent',
      color: '#21857F',
      '&:hover, &:active': {
        color: '#DB7A51',
      },
    },
  },
};

export type ButtonProps = {
  isLoading?: boolean;
  icon?: ComponentProps<typeof Icon>['icon'];
  href?: string;
  rel?: string;
  target?: string;
  to?: string;
  variant?: keyof typeof variants;
  disabled?: boolean;
  children: ReactNode;
  customBackgroudColor?: string;
  customBackgroudColorWithoutBorder?: string;
  customTextColor?: string;
  customBackgroudShadowColor?: string;
};

const StyledButton = styled(Box)<Pick<ButtonProps, 'variant' | 'customBackgroudColor' | 'customBackgroudShadowColor' | 'customBackgroudColorWithoutBorder' | 'customTextColor'>>`
  position: relative;
  appearance: none;
  text-decoration: none;

  ${(p) =>
    p.variant !== 'unstyled' &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      text-align: center;
      border: 0;
      border-radius: 8px;
      user-select: none;
      transition: background-color 0.18s ease-in-out, box-shadow 0.18s,
        border-color 0.18s ease-in-out, color 0.18s ease-in-out,
        opacity 0.18s ease-in-out, color 0.18s ease-in-out;

      &:active,
      &:focus {
        boxshadow: 0px 0px 0px 4px #d9e1dd;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.25;
      }

      &[data-is-loading] {
        cursor: wait;
        opacity: 1;
      }
    `}

  ${variant({ variants })}
  ${system}
  ${p => !!p.customBackgroudColor &&
    css`
      background-color: ${p.customBackgroudColor};
      border-color: ${p.customBackgroudColor};
      &:not(:disabled) {
        background-color: ${p.customBackgroudColor};
        border-color: ${p.customBackgroudColor};
      }
    `
  }
  ${p => !!p.customBackgroudShadowColor &&
    css`
      box-shadow: 0px 4px 0px ${p.customBackgroudShadowColor};
      &:hover, &:active: {
        box-shadow: 0px 0px 0px ${p.customBackgroudShadowColor};
      }
    `
  }
  ${p => !!p.customBackgroudColorWithoutBorder &&
    css`
      &:not(:disabled) {
        background-color: ${p.customBackgroudColorWithoutBorder};
      }
    `
  }
  ${p => !!p.customTextColor &&
    css`
      &:not(:disabled) {
        color: ${p.customTextColor};
      }
    `
  }
`;

const loadingStyles = {
  position: 'relative' as any,
  disabled: true,
  'data-is-loading': true,
  'aria-disabled': true,
};

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonProps & SystemProps,
  typeof _defaultElement
> = forwardRef(
  ({ children, icon, to, isLoading, ...props }: ButtonProps, ref: Ref<any>) => {
    const content = (
      <>
        {!!icon && <Icon width="24px" icon={icon} mr={1} />}
        {children}
      </>
    );

    return to ? (
      <Link href={to} passHref>
        <StyledButton as="a" variant="link" {...props}>
          {content}
        </StyledButton>
      </Link>
    ) : (
      <StyledButton
        as={props?.href ? 'a' : _defaultElement}
        variant={props?.href ? 'link' : 'primary'}
        display="flex"
        {...props}
        {...(isLoading ? loadingStyles : {})}
        ref={ref}
      >
        {isLoading ? (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Spinner color="inherit" size={18 as any} />
          </Box>
        ) : (
          content
        )}
      </StyledButton>
    );
  },
);
