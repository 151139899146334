type WindowWithDataLayer = Window & {
  dataLayer: any;
  gtag: any;
};

declare const window: WindowWithDataLayer;

export const gaViewItemEvent = async ({ currency, amount, item }) => {
  if (!!window.dataLayer) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(
      {
        event: 'view_item',
        ecommerce: {
          currency: currency,
          value: Number(amount),
          items: [{
            ...item,
            index: 0
          }]
        }
      }
    )
  }
}

export const gaViewListItemsEvent = async ({ list_name, items }) => {
  if (!!window.dataLayer) {
    const indexedItems = items.map((el, index) => ({
      ...el,
      index: index
    }))

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(
      {
        event: 'view_item_list',
        ecommerce: {
          item_list_name: list_name,
          items: indexedItems,
        }
      }
    )
  }
}

export const gaAddToCartEvent = async ({ currency, amount, items }) => {
  if (!!window.dataLayer) {
    const indexedItems = items.map((el, index) => ({
      ...el,
      index: index
    }))

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(
      {
        event: 'add_to_cart',
        ecommerce: {
          currency: currency,
          value: Number(amount),
          items: indexedItems
        }
      }
    )
  }
}

export const gaRemoveFromCartEvent = async ({ currency, amount, items }) => {
  if (!!window.dataLayer) {
    const indexedItems = items.map((el, index) => ({
      ...el,
      index: index
    }))

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(
      {
        event: 'remove_from_cart',
        ecommerce: {
          currency: currency,
          value: Number(amount),
          items: indexedItems
        }
      }
    )
  }
}

export const gaViewCartEvent = async ({ currency, amount, items }) => {
  if (!!window.dataLayer) {
    const indexedItems = items.map((el, index) => ({
      ...el,
      index: index
    }))

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(
      {
        event: 'view_cart',
        ecommerce: {
          currency: currency,
          value: Number(amount),
          items: indexedItems
        }
      }
    )
  }
}

export const gaBeginCheckoutEvent = async ({ currency, amount, items }) => {
  if (!!window.dataLayer) {
    const indexedItems = items.map((el, index) => ({
      ...el,
      index: index
    }))

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(
      {
        event: 'begin_checkout',
        ecommerce: {
          currency: currency,
          value: Number(amount),
          items: indexedItems
        }
      }
    )
  }
}
