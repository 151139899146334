import { Flex, css, Box, SystemProps } from '@storyofams/react-ui'
import { FormattedMessage } from 'react-intl'

import { Image, Button, Text, Heading } from '~components'
import { useShopify } from '~context'
import { CheckoutFragmentFragment } from '~lib/shopify/sdk'
import { QuantityInput } from './QuantityInput'
import { gaRemoveFromCartEvent, formatPrice } from '~lib'

export const CheckoutLineItem = ({
  title,
  variant,
  quantity,
  small,
  ...props
}: Partial<CheckoutFragmentFragment['lineItems']['edges'][number]['node']> & {
  small?: boolean
} & SystemProps) => {
  const { removeLineItem } = useShopify()

  return (
    <Flex alignItems="center" flexWrap={['wrap', 'nowrap']} {...props}>
      <Flex>
        <Box
          position="relative"
          flexShrink={0}
          mr={[1.5, 3]}
          width={small ? [40, 54] : [64, 80]}
          height={small ? [40, 54] : [64, 80]}
        >
          <Image
            src={variant?.image?.url}
            alt={variant?.image?.altText || variant?.product?.title || title}
            objectFit="contain"
            layout="fill"
            display="flex"
            width="100%"
            height="100%"
          />
        </Box>

        <Box width="100%" position="relative">
          <Flex flexDirection={'column'} gap={1}>
            <Box>
              <Heading
                variant="sh4"
                as="h4"
                flex={1}
                style={{ overflowWrap: 'anywhere', display: 'inline-block' }}
                mr={'0.5em'}
              >
                {variant?.product?.title || title}
              </Heading>
              {variant?.product?.tags.includes('portion_selectable') ? (
                <Text
                  display={'inline-block'}
                  fontSize={small ? 12 : 14}
                  fontWeight={['extraBold', 'bold']}
                  lineHeight={'1.5em'}
                  flex={1}
                >
                  ({variant?.selectedOptions[0].value})
                </Text>
              ) : (
                ''
              )}
            </Box>

            <Flex alignItems={'center'} gap={2}>
              <Text fontSize={'22px'} color={'#21857F'}>
                {formatPrice(variant?.priceV2)}
              </Text>
              <Button
                variant="unstyled"
                color="grey500"
                onClick={() => {
                  const portion = variant.selectedOptions.find(
                    el => el.name === 'Portion',
                  )
                  const gaData = {
                    currency: variant?.priceV2?.currencyCode,
                    amount: Number(variant?.priceV2?.amount) * quantity,
                    items: [
                      {
                        item_id: variant?.sku,
                        item_name: variant?.product?.title,
                        item_brand: variant?.product?.vendor,
                        item_variant: portion?.value,
                        price: Number(variant?.priceV2?.amount),
                        quantity: quantity,
                      },
                    ],
                  }

                  gaRemoveFromCartEvent(gaData)
                  removeLineItem(variant.id)
                }}
                transition="0.2s color ease-out"
                fontSize={1.5}
                css={css({
                  textTransform: 'uppercase',
                  '&:hover, &:focus': {
                    color: 'grey900',
                  },
                })}
              >
                <FormattedMessage defaultMessage="Remove" />
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      <QuantityInput
        variant={variant}
        quantity={quantity}
        ml={'auto'}
        mr={0}
        pl={3}
        px={0}
        small
        outline
      />
    </Flex>
  )
}
