import { Box, SystemProps, system } from '@storyofams/react-ui';
import styled from 'styled-components';
import { variant } from 'styled-system';

export const textVariants = {
  uppercase: {
    textTransform: 'uppercase',
    lineHeight: '1.25em',
    letterSpacing: '2px'
  },
  l: {
    fontSize: [3],
  },
  m: {
    fontSize: 2,
  },
  sm: {
    fontSize: 1.75,
    marginTop: 1.5,
  },
  s: {
    fontSize: 1.5,
  },
  label: {
    display: 'flex',

    lineHeight: 'normal',
    fontSize: 2,
    color: 'grey600',

    cursor: 'pointer',

    '&[disabled=""]': {
      cursor: 'not-allowed',
      opacity: 0.6,
    },
  },
  link: {
    fontSize: 2,
    textDecoration: 'underline',
  },
};

type CustomProps = {
  /** defaults to `p` */
  as?: 'p' | 'span' | 'blockquote' | 'strong' | 'em' | 'pre' | 'label' | 'a';
  variant?: keyof typeof textVariants;
  backgroundImage?: string;
} & SystemProps;

export const Text = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? 'p',
  variant: props?.variant || 'm',
}))<CustomProps>`
  font-family: ${(p) => p.theme.fonts.body};
  color: ${(p) => p.theme.colors.grey900};
  line-height: ${(p) => p.theme.lineHeights.medium};

  ${variant({ variants: textVariants })}
  ${system}
`;
